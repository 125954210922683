import { useEffect } from "react";
import useI18n from "./useI18n";
import { useSettings } from "../modules/accountAndSettings/hooks/useSettings";
import useAuth from "../store/auth/authState";

const GUEST_LANGUAGE_KEY = "guest_language_preference";

export const useLanguageSettings = () => {
  const { currentLanguage, changeLanguage } = useI18n("auth");
  const { settings } = useSettings();
  const { tokens } = useAuth();
  const isAuthenticated = !!tokens.accessToken;

  // Get guest language from localStorage
  const getGuestLanguage = () => {
    return localStorage.getItem(GUEST_LANGUAGE_KEY) || "uk";
  };

  // Save guest language to localStorage
  const saveGuestLanguage = (language: string) => {
    localStorage.setItem(GUEST_LANGUAGE_KEY, language);
  };

  // Handle language change
  const handleLanguageChange = (newLanguage: string) => {
    if (!isAuthenticated) {
      // For guests, save to localStorage
      saveGuestLanguage(newLanguage);
    }
    changeLanguage(newLanguage);
  };

  useEffect(() => {
    if (isAuthenticated && settings?.language) {
      // Logged in user - use settings language
      changeLanguage(settings.language);
    } else if (!isAuthenticated) {
      // Guest user - use stored guest preference
      const guestLanguage = getGuestLanguage();
      changeLanguage(guestLanguage);
    }
  }, [isAuthenticated, settings?.language]);

  return {
    currentLanguage,
    changeLanguage: handleLanguageChange,
  };
};
