import { logout } from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEY } from "../../../constants/constants";
import useAuth from "../../../store/auth/authState";
import { useHeader } from "../../../sharedComponents/HeaderContext";
import useSelection from "../../../store/selectionState/selectionState";
import useAppMode from "../../../store/appMode/appModeState";

export const useLogoutMutation = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { clearTokens } = useAuth();
  const { clearHistory, clearParentCategoriesHistory } = useHeader();
  const { clearSelection } = useSelection();
  const { setEditMode } = useAppMode();

  const resetStates = () => {
    // Immediately clear and remove all category queries
    queryClient.resetQueries({ queryKey: [QUERY_KEY.CATEGORIES] });
    queryClient.removeQueries({ queryKey: [QUERY_KEY.CATEGORIES] });

    // Set categories data to null explicitly
    queryClient.setQueryData([QUERY_KEY.CATEGORIES], null);

    // Clear all navigation and selection states
    clearHistory();
    clearParentCategoriesHistory();
    clearSelection();
    setEditMode(false);

    // Clear user data
    queryClient.setQueryData([QUERY_KEY.USER], null);
  };

  return useMutation({
    mutationFn: async () => {
      // Clear everything before API call
      resetStates();
      clearTokens();

      // Then perform logout API call
      return await logout();
    },
    onSuccess() {
      navigate("/auth/sign-in");
    },
    onError: (error) => {
      console.error("Logout failed", error);
      navigate("/auth/sign-in");
    },
  });
};
