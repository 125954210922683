import { useTranslation } from "react-i18next";

const useI18n = (namespace: string) => {
  const { t, i18n } = useTranslation(namespace);

  const changeLanguage = (language: string) => {
    if (i18n.language !== language) {
      i18n.changeLanguage(language);
      localStorage.setItem("i18nextLng", language);
      console.log("language changed to", language);
    }
  };

  return { t, changeLanguage, currentLanguage: i18n.language };
};

export default useI18n;
