import { useState, useEffect } from "react";

export const usePWAStatus = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isPWAInstalled, setIsPWAInstalled] = useState(false);
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    // Check if the app is installed (standalone mode or referrer hints)
    const checkInstalled = () => {
      const isStandalone =
        window.matchMedia("(display-mode: standalone)").matches ||
        (window.navigator as any).standalone ||
        document.referrer.includes("android-app://");
      setIsPWAInstalled(isStandalone);
    };

    // Safari detection
    const userAgent = navigator.userAgent.toLowerCase();
    setIsSafari(/safari/.test(userAgent) && !/chrome/.test(userAgent));

    checkInstalled();
    const mediaQuery = window.matchMedia("(display-mode: standalone)");
    mediaQuery.addEventListener("change", checkInstalled);

    return () => {
      mediaQuery.removeEventListener("change", checkInstalled);
    };
  }, []);

  useEffect(() => {
    // Listen for beforeinstallprompt event (not supported in Safari)
    const handler = (e: any) => {
      console.log("beforeinstallprompt event fired");
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const promptInstall = async () => {
    if (!deferredPrompt) return;
    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;

    if (outcome === "accepted") {
      console.log("PWA installation accepted");
      setDeferredPrompt(null);
    } else {
      console.log("PWA installation dismissed");
    }
  };

  return {
    isPWAInstalled,
    isSafari,
    canInstallPWA: !isPWAInstalled && (!!deferredPrompt || isSafari),
    promptInstall,
  };
};
