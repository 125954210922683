import { useEffect } from "react";

interface UsePreventZoomOptions {
  enableTouchScroll?: boolean;
}

export const usePreventZoom = (options: UsePreventZoomOptions = {}) => {
  const { enableTouchScroll = true } = options;

  useEffect(() => {
    // Prevent zoom on keyboard shortcuts
    const preventZoom = (e: KeyboardEvent) => {
      if (
        e.ctrlKey &&
        (e.key === "+" || e.key === "-" || e.key === "=" || e.key === "0")
      ) {
        e.preventDefault();
      }
    };

    // Prevent wheel zoom
    const preventWheelZoom = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    // Prevent touch gestures
    const preventTouchZoom = (e: TouchEvent) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    // Prevent double tap zoom
    let lastTouchEnd = 0;
    const preventDoubleTapZoom = (e: TouchEvent) => {
      const now = Date.now();
      if (now - lastTouchEnd <= 300) {
        e.preventDefault();
      }
      lastTouchEnd = now;
    };

    // Prevent iOS-specific gestures
    const preventGesture = (e: Event) => {
      e.preventDefault();
    };

    // Apply event listeners
    document.addEventListener("keydown", preventZoom);
    document.addEventListener("wheel", preventWheelZoom, { passive: false });

    if (!enableTouchScroll) {
      document.addEventListener("touchstart", preventTouchZoom, {
        passive: false,
      });
      document.addEventListener("touchmove", preventTouchZoom, {
        passive: false,
      });
    }

    document.addEventListener("touchend", preventDoubleTapZoom, {
      passive: false,
    });
    document.addEventListener("gesturestart", preventGesture);
    document.addEventListener("gesturechange", preventGesture);
    document.addEventListener("gestureend", preventGesture);

    // Clean up event listeners
    return () => {
      document.removeEventListener("keydown", preventZoom);
      document.removeEventListener("wheel", preventWheelZoom);

      if (!enableTouchScroll) {
        document.removeEventListener("touchstart", preventTouchZoom);
        document.removeEventListener("touchmove", preventTouchZoom);
      }

      document.removeEventListener("touchend", preventDoubleTapZoom);
      document.removeEventListener("gesturestart", preventGesture);
      document.removeEventListener("gesturechange", preventGesture);
      document.removeEventListener("gestureend", preventGesture);
    };
  }, [enableTouchScroll]);

  return {
    containerStyle: {
      position: "fixed" as const,
      width: "100%",
      height: "100%",
      overflow: "hidden",
    },
    contentStyle: {
      overflow: "auto",
      height: "100%",
    },
  };
};
