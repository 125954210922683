import React, { useState, useEffect } from "react";
import {
  Box,
  Switch,
  Text,
  Select,
  FormControl,
  FormLabel,
  Stack,
  Divider,
  Button,
  Flex,
  Input,
  NumberInput,
  NumberInputField,
  Spinner,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import useI18n from "../../../hooks/useI18n";
import { useSettings } from "../hooks/useSettings";
import { DEFAULT_SETTINGS } from "../services/localSettings.provider";

const Settings: React.FC = () => {
  const { settings, updateSettings, isLoading } = useSettings();
  const { t, changeLanguage, currentLanguage } = useI18n("account");
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState(DEFAULT_SETTINGS);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [pendingLanguage, setPendingLanguage] = useState(
    settings?.language || "uk",
  );


  useEffect(() => {
    if (settings) {
      setFormData(settings);
      setPendingLanguage(settings.language);
    }
  }, [settings]);

  useEffect(() => {
    const hasChanges =
      JSON.stringify(formData) !== JSON.stringify(settings) ||
      pendingLanguage !== settings?.language;
    setUnsavedChanges(hasChanges);
  }, [formData, pendingLanguage, settings]);

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => {
    const { name, value, type } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? (e.target as HTMLInputElement).checked : value,
    }));

    if (name === "language") {
      setPendingLanguage(value);
    }
  };

  const handleNumberChange = (value: string, name: string) => {
    const numValue = parseInt(value, 10) || 0;
    const validValue = Math.max(2, Math.min(6, numValue)); // Enforce min=2, max=6
    setFormData((prevData) => ({
      ...prevData,
      [name]: validValue,
    }));
  };

  const saveChanges = () => {
    updateSettings({ ...formData, language: pendingLanguage });
    changeLanguage(pendingLanguage);
  };

  const revertChanges = () => {
    if (settings) {
      setFormData(settings);
      setPendingLanguage(settings.language);
    }
  };

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  return (
    <Box
      w={"100%"}
      h={"100%"}
      mx="auto"
      p={8}
      bg="white"
      display="flex"
      flexDirection="column"
    >
      {/* Scrollable Stack Container */}
      <Box flexGrow={1} overflowY="auto" mb={4} pr={2}>
        <Text fontSize="2xl" fontWeight="bold" mb={6}>
          {t("account_modal.settings")}
        </Text>
        <Stack spacing={6}>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="language" mb="0" flex="1">
              {t("settings.language")}
            </FormLabel>
            <Select
              id="language"
              name="language"
              value={pendingLanguage}
              onChange={handleChange}
              width="150px"
            >
              <option value="en">{t("settings.english")}</option>
              <option value="uk">{t("settings.ukrainian")}</option>
            </Select>
          </FormControl>
          {/* <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="theme" mb="0" flex="1">
              {t("settings.theme")}
            </FormLabel>
            <Select
              id="theme"
              name="theme"
              value={formData.theme}
              onChange={handleChange}
              width="150px"
              disabled
            >
              <option value="light">{t("settings.light")}</option>
              <option value="dark">{t("settings.dark")}</option>
            </Select>
          </FormControl>

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="notificationsEnabled" mb="0" flex="1">
              {t("settings.notifications")}
            </FormLabel>
            <Switch
              id="notificationsEnabled"
              name="notificationsEnabled"
              isChecked={formData.notificationsEnabled}
              onChange={handleChange}
              size="md"
              width="50px"
              disabled
            />
          </FormControl> */}

          {/* <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="eyeTrackingMode" mb="0" flex="1">
              {t("settings.eye_tracking_mode")}
            </FormLabel>
            <Switch
              id="eyeTrackingMode"
              name="eyeTrackingMode"
              isChecked={formData.eyeTrackingMode}
              onChange={handleChange}
              size="md"
              width="50px"
            />
          </FormControl>

          {formData.eyeTrackingMode && (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="focusTime" mb="0" flex="1">
                {t("settings.focus_time")}
              </FormLabel>
              <NumberInput
                id="focusTime"
                name="focusTime"
                value={formData.focusTime}
                onChange={(value) => handleNumberChange(value, "focusTime")}
                width="150px"
                min={0}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
          )} */}

          {/* <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="voiceAssistantMode" mb="0" flex="1">
              {t("settings.voice_assistant")}
            </FormLabel>
            <Switch
              id="voiceAssistantMode"
              name="voiceAssistantMode"
              isChecked={formData.voiceAssistantMode}
              onChange={handleChange}
              size="md"
              width="50px"
            />
          </FormControl>

          {formData.voiceAssistantMode && (
            <FormControl display="flex" alignItems="center">
              <FormLabel htmlFor="wakeWord" mb="0" flex="1">
                {t("settings.wake_word")}
              </FormLabel>
              <Input
                id="wakeWord"
                name="wakeWord"
                value={formData.wakeWord}
                onChange={handleChange}
                width="150px"
              />
            </FormControl>
          )} */}

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="showCategoryImage" mb="0" flex="1">
              {t("settings.show_category_image")}
            </FormLabel>
            <Switch
              id="showCategoryImage"
              name="showCategoryImage"
              isChecked={formData.showCategoryImage}
              onChange={handleChange}
              size="md"
              width="50px"
            />
          </FormControl>

          <Divider />

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="columnCount" mb="0" flex="1">
              {t("settings.column_count")}
            </FormLabel>
            <NumberInput
              id="columnCount"
              name="columnCount"
              value={formData.columnCount}
              onChange={(value) => handleNumberChange(value, "columnCount")}
              width="150px"
              min={2}
              max={6}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </FormControl>
        </Stack>
      </Box>

      {/* Fixed Footer with Buttons */}
      {unsavedChanges && (
        <Flex justifyContent="flex-end" mt={4}>
          <Button onClick={saveChanges} colorScheme="teal" mr={4}>
            {t("buttons.save_changes")}
          </Button>
          <Button onClick={revertChanges} colorScheme="red">
            {t("buttons.revert_changes")}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default Settings;
